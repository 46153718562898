import PropTypes from 'prop-types';

import CollectionsContext from './CollectionsContext';
import CollectionsValue from './CollectionsValue';

const propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

const CollectionsProvider = ({ children }) => {
	const value = CollectionsValue();
	return (
		<CollectionsContext.Provider value={value}>
			{children}
		</CollectionsContext.Provider>
	);
};

CollectionsProvider.propTypes = propTypes;

export default CollectionsProvider;
