import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const CollectionsValue = () => {
	const history = useHistory();
	const [collections, setCollections] = useState(JSON.parse(localStorage.getItem('collections')) || []);
	const emptyCollection = {
		id: Date.now(),
		items: [],
		name: '',
	};

	const setCollectionsState = (updatedCollections) => {
		localStorage.setItem('collections', JSON.stringify(updatedCollections));
		setCollections(updatedCollections);
	};

	const addCollection = (collection) => {
		setCollectionsState([...collections, collection]);
	};

	const createCollection = () => {
		const untitledCollection = collections.find((collection) => collection.name === '');

		if (untitledCollection) {
			history.push({
				pathname: `/collection/${untitledCollection.id}`,
				state: {
					modal: true,
				},
			});
		} else {
			addCollection(emptyCollection);
			history.push({
				pathname: `/collection/${emptyCollection.id}`,
				state: {
					modal: true,
				},
			});
		}
	};

	const deleteCollection = (id) => {
		const newCollections = collections.filter((item) => item.id !== id);

		setCollectionsState(newCollections);
		history.replace('/');
	};

	const updateCollection = (id, newCollection) => {
		const index = collections.findIndex((item) => item.id === id);
		const newCollections = [...collections];
		newCollections.splice(index, 1, newCollection);

		setCollectionsState(newCollections);
	};

	return {
		collections,
		createCollection,
		deleteCollection,
		updateCollection,
	};
};

export default CollectionsValue;
